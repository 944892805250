import App from 'src/app';
import React from 'react';
import { init } from '@sentry/react';
import { render } from 'react-dom';
import AppProviders from 'src/providers';
import project from '../package.json';

const container = document.getElementById('root');
const [versionMajor, versionMinor, versionPatch] = project.version.split('.');
const sentryReleaseName = `${project.name}-v${versionMajor}.${versionMinor}.${versionPatch}`;
const isDevCommon = window.location.href.includes('devcommon');

if (import.meta.env.PROD && !isDevCommon) {
    if (sentryReleaseName) {
        init({
            dsn: 'https://e4b53b3b7f154b9d8611cbef32cd0bb5@o159730.ingest.sentry.io/1230652',
            defaultIntegrations: false,
            release: sentryReleaseName
        });
    } else {
        init({
            dsn: 'https://e4b53b3b7f154b9d8611cbef32cd0bb5@o159730.ingest.sentry.io/1230652',
            defaultIntegrations: false
        });
    }
}

render(
    <AppProviders>
        <App />
    </AppProviders>,
    container
);
